import React from "react";
import {Link} from "gatsby";

const PrimaryButton = props => (
  props.type === "submit" ?
    <button className="primary-button" type={props.type} disabled={props.disabled}>{props.children}</button> :
    <Link className="primary-button" to={props.to}>{props.children}</Link>
);

export default PrimaryButton;
